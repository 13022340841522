<template>
  <div>
    <div class="list-page" v-loading="loading">
      <el-tabs
        style="margin-bottom: 20px"
        v-model="activeName"
        @tab-click="handleTabClick"
      >
        <el-tab-pane
          :label="tab.label"
          :name="tab.name"
          v-for="(tab, index) in tabArr"
          :key="index"
        >
        </el-tab-pane>
      </el-tabs>
      <template v-if="activeName === 'detail'">
        <!-- 基本信息 -->
        <MenberDetail
          :checkMenber="checkMenber"
          :menberDetail="this.menberDetail"
        ></MenberDetail>
      </template>
      <template v-else-if="activeName === 'invite' && this.encodeData">
        <!-- 邀请列表 -->
        <InvitationRevenue :data="this.encodeData"></InvitationRevenue>
      </template>
    </div>
  </div>
</template>

<script>
import { getMemberDetail } from "../../api/benefits/benefits";
import ListImage from "@/base/components/List/ListImage";
// 基本信息
import MemberDetail from "../../components/benefits/MenberDetail.vue";
// 邀请列表
import InvitationRevenue from "../../components/benefits/InvitationRevenue.vue";
export default {
  components: { ListImage, InvitationRevenue, MemberDetail },
  data() {
    return {
      tabArr: [
        { label: "基本信息", name: "detail" },
        { label: "邀请列表", name: "invite" },
      ],
      loading: false, //加载
      activeName: this.$store.getters.menberActiveName,
      // 页面数据
      pageData: {},
      // 用户 id
      id: "",
      // 用户基本信息
      menberDetail: {},
      // encode 的 跳转路由数据
      encodeData: "",
    };
  },
  methods: {
    // 获取会员收益详情
    getMemberDetail() {
      this.loading = true;
      getMemberDetail({ id: this.id })
        .then((res) => {
          if (res.code) {
            this.menberDetail = res.data;
            let paramsData = {
              id: this.menberDetail.user_id,
              type: "formal",
              status: 1,
            };
            console.log(paramsData);
            // encode 的 跳转路由数据
            this.encodeData = this.$enCode(paramsData);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 跳转查看会员详情
    checkMenber(user_id) {
      let paramsData = {
        id: user_id,
        type: "formal",
        status: 1,
      };
      console.log(paramsData)
      this.$router.push({
        name: "EditMember",
        params: {
          data: this.$enCode(paramsData),
        },
      });
    },
    // 切换tab栏
    handleClick(e) {},
    // 切换选项卡
    handleTabClick(tab) {
      this.$store.commit("setMenberActiveName", this.activeName);
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
    },
  },
  created() {
    // 获取 id
    this.id = this.$route.params.id;
    this.getMemberDetail(); //获取列表数据
    console.log(this.$store.getters.menberActiveName);
  },
};
</script>

<style lang="scss" scoped>
.detail-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px 0;
  .detail-item {
    height: 100px;
    // background-color: pink;
    display: flex;
    font: 13px Small;
    .label {
    }

    .value {
      margin-left: 15px;
    }
  }
}
</style>
